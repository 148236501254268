@import url("./assets/styles/colors.css");

/* ---------------------------------------------------
    CSS VARIABLES
----------------------------------------------------- */
:root {
  --color-pear-green: #3eae83;
  --color-pear-green-highlight: #51b68f;
  --color-pear-green-muted: #9ed6c1;
  --color-pear-yellow: #d2dd8a;
  --color-pear-yellow-muted: #edf1d0;
  --color-pear-red: #d9534f;
  --color-pear-red-muted: #efbab8;
  --color-white-muted: #fafafa;
  --color-light-grey: #e1e1e1;
  --color-grey: #cacaca;
  --color-content-primary-disabled: rgba(0, 0, 0, 0.5);
  --color-seafoam: #02bdac;
  --color-creamsicle: #ff8338;
  --color-clean-yellow: #ffc107;
  --color-clean-yellow-highlight: #ffd351;
  --color-clean-yellow-muted: #ffe083;
  --color-clean-blue: #48a1bc;
  --color-clean-blue-muted: #8fe5ff;
  --color-clean-lime-muted: #cec881;
  --color-clean-lime: #61bd33;
}

/* ---------------------------------------------------
    GLOBAL BASE
----------------------------------------------------- */
.App {
  display: flex;
  width: 100vw;
  height: 100vw;
  overflow: hidden;
  align-items: stretch;
}

body {
  font-family: Avenir-Roman, Avenir-Book, sans-serif;
  background: var(--color-white-muted);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: KeepCalm-Medium, sans-serif;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
}

.bg-pear-green {
  background-color: var(--color-pear-green);
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 200px;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.btn-outline {
  border: 2px solid #12663f;
}

.nav-select {
  border-bottom: 1px solid var(--color-pear-green-muted);
  z-index: 10;
}
.menu-icon {
  top: 0;
  z-index: 2;
  transition: all 0.3s;
  outline: none !important;
  box-shadow: none !important;
}

/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
.text-small {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.details-value {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
}
.text-categories {
  font-size: 16px;
  color: #938c8c;
  padding: 0.3rem 1rem !important;
}

.text-pear-green,
.text-categories:active {
  color: var(--color-pear-green);
}
