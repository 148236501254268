.questionHeader .actionIcons {
  display: none;
}

.questionHeader:hover .actionIcons {
  display: flex;
}

.questionText {
  font-size: 14px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.questionText > p {
  margin: 0;
}

.questionText > h3,
.questionText > h4 {
  margin: 4px 0;
}
