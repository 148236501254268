:root {
  --GREEN: #3eae83;
  --BLUE: #2181c3;
  --BEIGE: #d2dd8a;
  --CREAM: #f3eac0;
  --COAL: #3b3b3b;
  --DANGER: #d9534f;
  --DANGER-HIGHLIGHT: #dc6460;
  --DANGER-MUTED: #fbeded;
}
